import React, { useState, useEffect } from "react";

const App = () => {
  const [iframeVisible, setIframeVisible] = useState(false);

  useEffect(() => {
    // Event listener for messages from the iframe
    const handleIframeMessage = (event) => {
      // Ensure the message comes from the expected origin
      const expectedOrigin = "https://dashboard.dupr.com";
      if (event.origin !== expectedOrigin) {
        console.warn("Message received from unexpected origin:", event.origin);
        return;
      }

      // Log the data received from the iframe
      console.log("Message received from iframe:", event.data);

      // Close the iframe if needed
      setIframeVisible(false);
    };

    window.addEventListener("message", handleIframeMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  const openIframe = () => {
    setIframeVisible(true);
  };

  return (
    <div>
      <h1>ReactJS Iframe Example</h1>
      <button
        onClick={openIframe}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Open Iframe
      </button>

      {iframeVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <iframe
            src="https://dashboard.dupr.com/login-external-app/Zjc1ZmUzMzktYTQ1My00ZmFjLWY5MjQtZDg1ZjkxYzA4OTE5"
            title="DUPR Login"
            style={{
              width: "80%",
              height: "80%",
              border: "none",
              borderRadius: "8px",
            }}
          />
          <button
            onClick={() => setIframeVisible(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "red",
              color: "white",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
